<template>
  <v-row class="mb-15 mt-5">
    <v-col cols="12">
      <v-card outlined class="pa-2" flat>
        <v-row align="center">
          <v-col cols="auto">
            <v-avatar outlined size="50">
              <v-icon
                color="primary"
                v-text="'mdi-account-group-outline'"
              ></v-icon>
            </v-avatar>
          </v-col>
          <v-col v-if="!loading && !isInfoLoaded" cols="auto">
            <div class="titleFont">
              {{ getTeamName }}
              <span v-if="getCurrentPlan?.name && canManageTeam">
                <v-chip
                  small
                  :color="$options?.PLANICONS[getCurrentPlan?.name]?.color"
                >
                  <v-icon
                    class="font-16"
                    left
                    v-text="$options.PLANICONS[getCurrentPlan?.name]?.icon"
                  ></v-icon>
                  {{ getCurrentPlan?.name }}
                </v-chip>
              </span>
            </div>
            <div>
              <vue-hoverable-date
                v-if="info.created_at"
                :date="info.created_at"
              ></vue-hoverable-date>
            </div>
          </v-col>
          <v-col v-else cols="auto">
            <div>
              <v-skeleton-loader type="list"></v-skeleton-loader>
            </div>
          </v-col>
          <v-col>
            <div class="top float-right">
              <v-tooltip v-if="canManageTeam" bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn :disabled="loading" icon @click="onUpdateClick">
                      <v-icon class="font-20">edit</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span> Update Information </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn :disabled="loading" icon @click="switchModal = true">
                      <v-icon class="font-20">mdi-transit-transfer</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span> Switch Team </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn :disabled="loading" icon @click="onLeaveClick">
                      <v-icon class="font-20">logout</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span> Leave Team </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col v-if="canViewTeamMembers" cols="12">
      <v-tabs v-model="tab">
        <template v-for="(value, key) in tabs">
          <tab
            :key="key"
            :label="value.label"
            v-if="value.permissions"
            :locked="value.locked"
            @click="$router.push({ name: value.value })"
          />
        </template>
      </v-tabs>
      <v-divider></v-divider>
      <div class="mt-4">
        <router-view></router-view>
      </div>
    </v-col>
    <SidePanel v-model="drawer" @close="drawer = false">
      <!--  -->
      <update-team-info
        v-if="drawer"
        :info="info"
        @close="closeUpdateDrawer"
      ></update-team-info>
      <!--  -->
    </SidePanel>
    <Modal v-model="leaveTeamModal">
      <template #card>
        <leave-team-modal @close="leaveTeamModal = false"></leave-team-modal>
      </template>
    </Modal>
    <Modal v-model="switchModal">
      <template #card>
        <v-card v-if="switchModal">
          <team-list from-team-page @close="switchModal = false"></team-list>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="teamActionModal">
      <template #card>
        <v-card v-if="teamActionModal">
          <v-card-title>
            <div>Update Info</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="teamActionModal = falses">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <update-team-info
              @close="teamActionModal = false"
            ></update-team-info>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { isEmpty, get } from "@/core/services/helper.service";
import { GET_TEAM_INFO } from "@/store/team/manageTeam.module";
import { mapState, mapGetters, mapMutations } from "vuex";
import Loader from "@/view/components/Common/VueLoader.vue";
import { LEAVE_TEAM } from "@/store/team/manageTeam.module";
import { CLEAR_STORE } from "@/store/users/auth.module";
import UpdateTeamInfo from "@/view/components/Team/UpdateTeamInfo";
import Tab from "@/view/components/Common/Tab";
import LeaveTeamModal from "@/view/components/Team/LeaveTeamModal";
import TeamList from "@/view/components/Team/List";
export default {
  PLANICONS: {
    Freelancer: {
      icon: "mdi-water-circle",
      color: "error",
    },
    Starter: {
      icon: "mdi-timeline",
      color: "info",
    },
    Pro: {
      icon: "mdi-seal",
      color: "success",
    },
  },
  TABS: {
    "team-members": 0,
    invites: 1,
    storage: 2,
    "team-integrations": 3,
    "integration-source": 3,
    "integration-sub-source": 3,
    "slack-integration-auth": 3,
    "team-plan": 4,
    "team-usage": 4,
    "team-plan-history": 5,
  },
  data() {
    return {
      loading: false,
      breadCrumbsItem: {
        "team-members": "Members",
        invites: "Invites",
        storage: "Storage",
        plans: "Plans",
        integrations: "Integrations",
        timeline: "Timeline",
      },
      errorMessage: null,
      tab: 0,
      teamActionModal: false,
      leaveTeamModal: false,
      switchModal: false,
      drawer: false,
      busy: false,
      readMore: false,
      update: {},
      breadCrumbs: [
        { text: "Team", disabled: false, to: { name: "team" } },
        { text: "...", disabled: false, to: { name: "team" } },
        { text: "...", disabled: true, to: { name: "team" } },
      ],
    };
  },
  components: {
    TeamList,
    LeaveTeamModal,
    Tab,
    UpdateTeamInfo,
  },
  computed: {
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
    }),
    ...mapGetters({
      user: "currentUser",
      info: "getTeamInfo",
      getTeamName: `getTeamName`,
      getCurrentPlan: "getCurrentPlan",
      userPermissions: "userPermissions",
    }),
    tabs() {
      return {
        members: {
          label: "Members",
          value: "team-members",
          permissions: this.canViewTeamMembers,
        },
        invites: {
          label: "Invites",
          value: "invites",
          permissions: this.canViewInvite,
        },
        storage: {
          label: "Storage",
          value: "storage",
          permissions: this.canViewStorage,
        },
        integration: {
          label: "Integrations",
          value: "team-integrations",
          permissions: this.canManageTeam,
        },
        plan: {
          label: "Plan",
          value: "team-plan",
          permissions: this.canManageTeam,
        },
        history: {
          label: "Transactions",
          permissions: this.canManageTeam,
          value: "team-plan-history",
        },
      };
    },
    noData() {
      return isEmpty(this.info) ? true : false;
    },
  },
  watch: {
    "$route.name"(val) {
      this.tab = this.$options.TABS[val];
    },
  },
  created() {
    this.tab = this.$options.TABS[this.$route.name];
    this.getTeamInfo();
    if (this.$vuetify.theme.dark) {
      this.$vuetify.theme.dark = true;
    }
  },

  methods: {
    getTeamInfo() {
      this.errorMessage = false;
      if (this.loading) return;
      this.loading = true;
      if (isEmpty(this.info)) {
        this.$store
          .dispatch(GET_TEAM_INFO)
          .then(() => {
            this.loading = false;
            if (this.info && this.info.name) {
              this.breadCrumbs[1].text = this.info.name;
            }
          })
          .catch((err) => {
            this.errorMessage = err.message;
            if (err.status === 401) {
              this.$router.push({ name: "welcome" });
            }
            this.$store.dispatch(CLEAR_STORE);
          });
      }
      this.loading = false;
    },
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
    }),
    onLeaveClick() {
      this.leaveTeamModal = true;
    },
    onUpdateClick() {
      this.teamActionModal = true;
    },
    closeUpdateDrawer(name) {
      this.drawer = false;
      this.breadCrumbs[1].text = name;
    },
    onCancel() {
      this.modal = false;
    },
    onConfirm() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(LEAVE_TEAM)
        .then((response) => {
          this.busy = false;
          this.notifyUserMessage({
            message: response.data.message || "Successfully left the Team",
          });
          this.$store.dispatch(CLEAR_STORE);
          this.updateUserPermission({});
          this.$nextTick(() => {
            this.$router.push({ name: "welcome" }).catch(() => {});
          });
        })
        .catch((err) => {
          this.busy = false;
          this.modal = false;
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/sass/pages/manage-Team";
// @import "@/assets/sass/custom/table-theme";
</style>
