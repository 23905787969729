var render = function render(){
  var _vm$getCurrentPlan, _vm$$options, _vm$$options$PLANICON, _vm$getCurrentPlan2, _vm$$options$PLANICON2, _vm$getCurrentPlan3, _vm$getCurrentPlan4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mb-15 mt-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "outlined": "",
      "size": "50"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    },
    domProps: {
      "textContent": _vm._s('mdi-account-group-outline')
    }
  })], 1)], 1), !_vm.loading && !_vm.isInfoLoaded ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "titleFont"
  }, [_vm._v(" " + _vm._s(_vm.getTeamName) + " "), (_vm$getCurrentPlan = _vm.getCurrentPlan) !== null && _vm$getCurrentPlan !== void 0 && _vm$getCurrentPlan.name && _vm.canManageTeam ? _c('span', [_c('v-chip', {
    attrs: {
      "small": "",
      "color": (_vm$$options = _vm.$options) === null || _vm$$options === void 0 ? void 0 : (_vm$$options$PLANICON = _vm$$options.PLANICONS[(_vm$getCurrentPlan2 = _vm.getCurrentPlan) === null || _vm$getCurrentPlan2 === void 0 ? void 0 : _vm$getCurrentPlan2.name]) === null || _vm$$options$PLANICON === void 0 ? void 0 : _vm$$options$PLANICON.color
    }
  }, [_c('v-icon', {
    staticClass: "font-16",
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s((_vm$$options$PLANICON2 = _vm.$options.PLANICONS[(_vm$getCurrentPlan3 = _vm.getCurrentPlan) === null || _vm$getCurrentPlan3 === void 0 ? void 0 : _vm$getCurrentPlan3.name]) === null || _vm$$options$PLANICON2 === void 0 ? void 0 : _vm$$options$PLANICON2.icon)
    }
  }), _vm._v(" " + _vm._s((_vm$getCurrentPlan4 = _vm.getCurrentPlan) === null || _vm$getCurrentPlan4 === void 0 ? void 0 : _vm$getCurrentPlan4.name) + " ")], 1)], 1) : _vm._e()]), _c('div', [_vm.info.created_at ? _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.info.created_at
    }
  }) : _vm._e()], 1)]) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list"
    }
  })], 1)]), _c('v-col', [_c('div', {
    staticClass: "top float-right"
  }, [_vm.canManageTeam ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "disabled": _vm.loading,
            "icon": ""
          },
          on: {
            "click": _vm.onUpdateClick
          }
        }, [_c('v-icon', {
          staticClass: "font-20"
        }, [_vm._v("edit")])], 1)], 1)];
      }
    }], null, false, 3010530075)
  }, [_c('span', [_vm._v(" Update Information ")])]) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "disabled": _vm.loading,
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.switchModal = true;
            }
          }
        }, [_c('v-icon', {
          staticClass: "font-20"
        }, [_vm._v("mdi-transit-transfer")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" Switch Team ")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          attrs: {
            "disabled": _vm.loading,
            "icon": ""
          },
          on: {
            "click": _vm.onLeaveClick
          }
        }, [_c('v-icon', {
          staticClass: "font-20"
        }, [_vm._v("logout")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" Leave Team ")])])], 1)])], 1)], 1)], 1), _vm.canViewTeamMembers ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.tabs, function (value, key) {
    return [value.permissions ? _c('tab', {
      key: key,
      attrs: {
        "label": value.label,
        "locked": value.locked
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: value.value
          });
        }
      }
    }) : _vm._e()];
  })], 2), _c('v-divider'), _c('div', {
    staticClass: "mt-4"
  }, [_c('router-view')], 1)], 1) : _vm._e(), _c('SidePanel', {
    on: {
      "close": function close($event) {
        _vm.drawer = false;
      }
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_vm.drawer ? _c('update-team-info', {
    attrs: {
      "info": _vm.info
    },
    on: {
      "close": _vm.closeUpdateDrawer
    }
  }) : _vm._e()], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('leave-team-modal', {
          on: {
            "close": function close($event) {
              _vm.leaveTeamModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.leaveTeamModal,
      callback: function callback($$v) {
        _vm.leaveTeamModal = $$v;
      },
      expression: "leaveTeamModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.switchModal ? _c('v-card', [_c('team-list', {
          attrs: {
            "from-team-page": ""
          },
          on: {
            "close": function close($event) {
              _vm.switchModal = false;
            }
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.switchModal,
      callback: function callback($$v) {
        _vm.switchModal = $$v;
      },
      expression: "switchModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.teamActionModal ? _c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Update Info")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.teamActionModal = _vm.falses;
            }
          }
        }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('update-team-info', {
          on: {
            "close": function close($event) {
              _vm.teamActionModal = false;
            }
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.teamActionModal,
      callback: function callback($$v) {
        _vm.teamActionModal = $$v;
      },
      expression: "teamActionModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }